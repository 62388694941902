import { useCallback, useEffect, useState } from 'react';
import {
  InformationCircleIcon, VideoCameraIcon, CheckIcon, SparklesIcon,
} from '@heroicons/react/24/solid';
import {
  Anchor, Avatar, Modal, RatingScore,
} from '@components/common';
import { useTranslation } from 'next-i18next';
import { HireSalaryModal } from '@components/templates/cv/partials/modals/HireSalaryModal';
import { fetcher, isApiV1Errors } from '@utils/data-fetching';
import sendGoogleTagEvent from '@hooks/useSendGoogleTagEvent';
import { VideoResumeModal } from '@components/templates/cv/partials/modals/VideoResumeModal';
import { route } from '@services/symfony';
import { useStaticToasts } from '@hooks/useStaticToasts';
import { JobFolderSelector } from '@components/templates/cv/partials/JobFolderSelector';
import { ResumeSocialIconById } from '@components/templates/profile/common/ResumeSocialIconById';
import { locationPreferencesKeys } from '@utils/resume/job-type-preferences';
import { useSearchParams } from 'next/navigation';
import { ProfileAttachments } from '@components/templates/cv/sections/ProfileAttachments';
import { useAppSelector } from '@store/index';
import { getUserHeaderInfo } from '@store/reducers/userSelector';
import {
  CvDetailAvailableOperationsCurrentStatus,
  CvDetailObject, CvDetailPreferences,
  IUnpaidInvoiceInterface,
} from '../interface/CvDetailInterface';

function IconUnpaidOrExpired() {
  return (
    <svg className="size-8" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="0.5" width="32" height="32" rx="16" fill="#FFC107" />
      <path d="M24.7812 20.7812L17.2812 7.75C16.7188 6.78125 15.25 6.75 14.6875 7.75L7.1875 20.7812C6.625 21.75 7.34375 23 8.5 23H23.4688C24.625 23 25.3438 21.7812 24.7812 20.7812ZM16 18.0625C16.7812 18.0625 17.4375 18.7188 17.4375 19.5C17.4375 20.3125 16.7812 20.9375 16 20.9375C15.1875 20.9375 14.5625 20.3125 14.5625 19.5C14.5625 18.7188 15.1875 18.0625 16 18.0625ZM14.625 12.9062C14.5938 12.6875 14.7812 12.5 15 12.5H16.9688C17.1875 12.5 17.375 12.6875 17.3438 12.9062L17.125 17.1562C17.0938 17.375 16.9375 17.5 16.75 17.5H15.2188C15.0312 17.5 14.875 17.375 14.8438 17.1562L14.625 12.9062Z" fill="white" />
    </svg>
  );
}

export interface InterfaceJobFolderOptions {
  jobId:number;
  title:string
  jobSlug:string
}

export const ProfileHeading = ({
  cvDetail,
  jobFolderSlug,
  jobFolderContext,
  sendJobFolderInfoToParent,
  currentJobFolderItem,
  currentCVStatus,
  applicantSeenContactStatus,
  phoneHighlight,
  openLinksInNewTab = false,
  isCompany,
  locale,
}: { cvDetail:CvDetailObject;
  jobFolderSlug:string;
  applicantSeenContactStatus:boolean;
  phoneHighlight:boolean;
  sendJobFolderInfoToParent:(item:InterfaceJobFolderOptions)=>void | string;
  jobFolderContext:boolean;
  currentCVStatus:CvDetailAvailableOperationsCurrentStatus | undefined;
  currentJobFolderItem:InterfaceJobFolderOptions
  openLinksInNewTab?:boolean;
  isCompany?: boolean;
  locale: Locale;
}) => {
  const { t } = useTranslation('common');

  const [unpaidInvoice, setUnpaidInvoice] = useState<IUnpaidInvoiceInterface>();
  const [salaryModal, setSalaryModal] = useState<boolean>(false);
  const [videoModal, setVideoModal] = useState<boolean>(false);
  const [hasBeenConfirmedFromHiredModal, setHasBeenConfirmedFromHiredModal] = useState<boolean>(false);
  const applicantID = cvDetail?.id ? cvDetail.id : 0;
  const [isAllowedShortData, setIsAllowedShortData] = useState<boolean>(applicantSeenContactStatus);
  const rid = useSearchParams()?.get('rid');
  const { showStatusToast } = useStaticToasts();
  const availableForRelocation = cvDetail?.preferences?.some((item: CvDetailPreferences) => item?.value === t(locationPreferencesKeys.relocation));

  const headerInfoData = useAppSelector(getUserHeaderInfo);

  const fetchCompanyStatus = useCallback(() => {
    fetcher('/v1/company/get-status', locale, {
      method: 'GET',
      stringifyPayload: true,
    }).then((response) => {
      setUnpaidInvoice(response as IUnpaidInvoiceInterface);
    }).catch(() => {});
  }, [locale]);

  useEffect(() => {
    if (isCompany) {
      fetchCompanyStatus();
    }
  }, [fetchCompanyStatus, isCompany]);

  // If the Resume changes then priority of contacted data is set to resume object else we
  // get the trigger from ProfileOperations components from Call button
  useEffect(() => {
    const condition = typeof cvDetail?.application?.applicantSeenContact === 'boolean' ? cvDetail?.application?.applicantSeenContact : false;
    setIsAllowedShortData(condition);
  }, [cvDetail]);

  useEffect(() => {
    setIsAllowedShortData(applicantSeenContactStatus);
  }, [applicantSeenContactStatus]);

  const succesfullHiredModal = () => {
    setSalaryModal(false);
    setHasBeenConfirmedFromHiredModal(true);
  };

  let cvStatusParentColor;
  let cvStatusIconColor;

  switch (currentCVStatus?.id) {
    case '3':
      cvStatusParentColor = ' text-pink';
      cvStatusIconColor = ' bg-pink/10 ';
      break;
    case '21':
      cvStatusParentColor = ' text-primary';
      cvStatusIconColor = ' bg-primary/10 ';
      break;
    case '4':
      cvStatusParentColor = ' text-error';
      cvStatusIconColor = ' bg-error/10';
      break;
    case '2':
      cvStatusParentColor = ' text-secondary';
      cvStatusIconColor = ' bg-secondary/10';

      break;
    case '22':
      cvStatusParentColor = ' text-secondary';
      cvStatusIconColor = ' bg-secondary/10';
      break;
    case '23':
      cvStatusParentColor = ' text-warning';
      cvStatusIconColor = ' bg-warning/20';
      break;
    default:
      cvStatusParentColor = ' text-primary';
      cvStatusIconColor = ' bg-primary/10';
  }

  const sendContactedData = () => {
    fetcher(`/v1/resume-operation/${applicantID}/contact?contact_type=7${rid ? `&rid=${rid}` : ''}`, locale, {
      method: 'POST',
      payload: {
        type: 'personal_info',
        jobSlug: jobFolderSlug,
      },
      stringifyPayload: true,
    }).then((response) => {
      if (isApiV1Errors(response)) {
        const { code } = response.errors[0];
        showStatusToast(String(`${code}`));
      } else {
        setIsAllowedShortData(true);
      }
    }).catch(() => {
    });
  };

  const { averageRating = 0, reviewsCount = 0 } = cvDetail?.talentRatingData ?? {};


  return (
    <>
      <section className="">

        <div className="block">

          <div className="mb-5 flex items-center justify-between">

            <div>
              {cvDetail?.interest
                && (
                  <span className="text-sm text-ink-medium">
                    {cvDetail?.interest?.value}
                  </span>
                )}

              {cvDetail?.activityStatus !== undefined && jobFolderContext
                && (
                  <span className="flex min-w-fit items-center text-sm text-ink-medium">
                    <InformationCircleIcon width={14} className="mr-1 text-secondary" />
                    {t('cv.detail.active_user')}
                  </span>
                )}
            </div>


            {(currentCVStatus !== undefined || cvDetail?.hasUserSubscription) && (
              <div className="flex items-start gap-4">
                {currentCVStatus !== undefined && (
                  <div className={`flex items-center font-semibold ${cvStatusParentColor}`}>
                    <div className={`mr-2 w-full items-center justify-center rounded-full px-4 py-1 text-sm ${cvStatusIconColor}`}>
                      {currentCVStatus?.name}
                    </div>

                  </div>
                )}
                {cvDetail?.hasUserSubscription && (
                  <span className="inline-flex items-center justify-center gap-x-1 rounded-full bg-pink/10 px-3 py-1 text-tiny font-semibold uppercase text-pink">
                    <SparklesIcon className="size-4" />
                    {t('cv.detail.premium')}
                  </span>
                )}
              </div>
            )}

          </div>

          <div id="about-me" className="relative mx-auto flex w-fit flex-col items-center justify-center">
            {/* Avatar */}
            <Avatar
              size="xl"
              name={cvDetail?.privateInformation?.name}
              imgPath={cvDetail?.imgUrl}
              imgFolder="resume_image"
              extractImageName
              imgPriority
              withRing
              alt="Profile picture"
              className="rounded-full !outline-2 !outline-offset-3 !outline-secondary !ring-4"
            />

            {cvDetail?.privateInformation?.videoUrl
                && (

                <>
                  <Modal
                    size="sm"
                    isOpen={videoModal}
                    header="Video"
                    body={(
                      <VideoResumeModal
                        getDataFromUrl={cvDetail?.privateInformation?.videoUrl}
                        locale={locale}
                      />
                    )}
                    onClose={() => { setVideoModal(false); }}
                  />

                  <span role="presentation" onClick={() => setVideoModal(true)} className="absolute bottom-0 left-1/2 flex min-w-24 -translate-x-1/2 cursor-pointer items-center rounded-xl bg-surface px-2 text-pico font-semibold uppercase text-ink shadow-xl">
                    <VideoCameraIcon width={13} className="-mt-0.5 mr-1 text-primary" />
                    {t('profile.similar.has.video.cv')}
                  </span>
                </>
                )}


            {averageRating > 0 && reviewsCount > 0
              && (
              <div className="mx-auto mt-4 w-fit rounded-md border border-dashed p-2">
                <RatingScore className="size-4 text-warning" score={averageRating} numberOfRatings={reviewsCount} showScore={false} />
              </div>
              )}

          </div>

          <div className="col-span-3 mt-3 border-b py-3">

            {cvDetail?.privateInformation
              && (
                <h2 className="text-2xl font-bold">{cvDetail.privateInformation.name}</h2>
              )}

            {/* If the CV is not unblocked then show the lead experience as title */}
            {!cvDetail?.accessToPrivateInformation && cvDetail?.leadExperience
              && (
              <>
                <h2 className="text-2xl font-bold">{cvDetail?.leadExperience?.title}</h2>
                <p className="block text-sm font-normal">{cvDetail?.leadExperience?.company}</p>
              </>
              )}

            <div>
              <span className="block text-sm font-normal">
                {cvDetail?.location && `${cvDetail.location}`}
                {availableForRelocation && (
                <>
                  {' '}
                  (
                  {t(locationPreferencesKeys.relocation)}
                  )
                </>
                )}
                {', '}
                {cvDetail?.age
                    && (
                    <>
                      {`${cvDetail.age} ${t('cv.card.label.years')}, `}
                    </>
                    )}
                {cvDetail?.gender && (
                <>
                  {`${cvDetail.gender}`}
                </>
                )}
              </span>
            </div>

            {!!cvDetail?.citiesOfInterest?.length && (
              <div className="text-sm">
                <span className="text-ink-medium">{ t('cv.detail.cities_of_interest')}</span>
                {` ${cvDetail.citiesOfInterest.join(', ')}` }
              </div>
            )}

            {(isAllowedShortData || cvDetail?.isOwnCV)
            && (
            <>
              {cvDetail?.privateInformation && (cvDetail?.locked === false || cvDetail?.isOwnCV)
                && (
                <>
                  {cvDetail?.privateInformation?.phone
                      && (
                      <>
                        <div className={`${phoneHighlight ? 'inline-block rounded-sm bg-info px-3 text-surface' : 'text-primary'} mt-2 transition-all`}>
                          <a onClick={() => sendGoogleTagEvent('employer_cv_phone_call_clicked', 'Custom')} href={`tel:${cvDetail.privateInformation.phone}`} className="inline-block text-sm font-normal">
                            {cvDetail.privateInformation.phone}
                          </a>
                        </div>
                      </>
                      )}
                  {cvDetail?.privateInformation?.email
                      && <div><a onClick={() => sendGoogleTagEvent('employer_cv_email_send_clicked', 'Custom')} href={`mailto:${cvDetail.privateInformation.email}`} className="inline-block text-sm font-normal text-primary">{cvDetail.privateInformation.email}</a></div>}
                </>
                )}
            </>
            )}

            {!isAllowedShortData && !cvDetail?.isOwnCV && (cvDetail?.privateInformation?.phone || cvDetail?.privateInformation?.email) && (
            <>
              <div
                role="presentation"
                onClick={() => sendContactedData()}
                className="mt-2 inline-block cursor-pointer text-sm font-normal text-primary"
              >
                {cvDetail?.privateInformation?.phone
                    && (
                    <div>
                      {`${cvDetail?.privateInformation?.phone.slice(0, 5)} ... ${cvDetail?.privateInformation?.phone.slice(-3)}`}
                    </div>
                    )}

                {cvDetail?.privateInformation?.email
                      && (
                      <div>
                        {`${cvDetail?.privateInformation?.email.replace(/^[^@]*/, '...')}`}
                      </div>
                      )}
              </div>
            </>
            )}

            <div className="mt-2 flex flex-wrap items-center justify-start">
              {
                      cvDetail?.privateInformation?.personalLinks
                      && cvDetail.privateInformation.personalLinks.map((social) => {
                        if (!social.url?.length) {
                          return null;
                        }

                        return (
                          <Anchor key={social.id} href={social.url} styling="text" color="primary" target="_blank">
                            <div className="mb-2 mr-4 flex items-center text-sm text-ink">
                              {ResumeSocialIconById(social.id, 'w-4 h-4')}
                              <span className="ml-1 inline-block">{social.name}</span>
                            </div>
                          </Anchor>
                        );
                      })
                  }
            </div>

            {!cvDetail?.locked
              && (
                <ProfileAttachments
                  cvDetail={cvDetail}
                />
              )}

            {cvDetail?.hiredConfirm && !hasBeenConfirmedFromHiredModal
                && (
                <>
                  <div
                    role="presentation"
                    className="my-2 mr-3 flex w-fit cursor-pointer items-center
                    rounded bg-secondary px-4 py-1 text-sm text-surface shadow-xl"
                    onClick={() => setSalaryModal(true)}
                  >
                    <CheckIcon width={15} className="mr-2 text-surface" />
                    {t('hired-applicant.confirm.btn')}
                  </div>

                  <Modal
                    size="sm"
                    isOpen={salaryModal}
                    header={t('confirm_hired.modal.title')}
                    body={(
                      <HireSalaryModal
                        applicantId={applicantID}
                        endpoint=""
                        onSendSuccess={succesfullHiredModal}
                        comingFrom="confirm_salary_id"
                        locale={locale}
                      />
                    )}
                    onClose={() => { setSalaryModal(false); }}
                  />
                </>
                )}
          </div>
        </div>
      </section>

      {/* If the CV is Locked, show Job Folder Selector */}
      {cvDetail?.locked === true && jobFolderContext && headerInfoData?.role === 'COMPANY' && (
        <div className="w-full border-b md:inline-flex">
          <JobFolderSelector
            selectedJobFolder={currentJobFolderItem}
            sendJobFolderInfoToParent={sendJobFolderInfoToParent}
            className="mx-auto my-4 flex-1"
            showPrice={unpaidInvoice?.contactCreditInfo
            && (unpaidInvoice?.contactCreditInfo?.totalAvailableCreditsNr < 10)
                && ((unpaidInvoice?.premiumInfo?.stats?.includedContactStats?.available || 0) <= 0)
                && ((unpaidInvoice?.premiumInfo?.stats?.extraContactStats?.available || 0) <= 0)}
          />

        </div>
      )}
      {cvDetail?.locked === true && !jobFolderContext && unpaidInvoice?.contactCreditInfo
          && (unpaidInvoice?.contactCreditInfo?.totalAvailableCreditsNr < 10)
          && ((unpaidInvoice?.premiumInfo?.stats?.includedContactStats?.available || 0) <= 0)
          && ((unpaidInvoice?.premiumInfo?.stats?.extraContactStats?.available || 0) <= 0) && (
          <div className="w-full border-b py-3 text-sm text-ink-medium md:inline-flex">
            {t('cv.detail.contact.label')}
            :
            {t('label-10-credits')}
          </div>
      )}

      {/* If the user has unpaid invoices let him know */}
      {unpaidInvoice !== undefined && unpaidInvoice?.orderInfo?.type === 'expired' && unpaidInvoice?.orderInfo?.orders?.length > 0 && unpaidInvoice?.bannedFromUsingServices
          && (
          <div className="flex items-center justify-start border-b p-4">
            <div className="w-10 min-w-10">
              <IconUnpaidOrExpired />
            </div>
            <div className="ml-2">
              <span className="block text-sm font-bold">{t('cv.detail.unpaid_or_expired_invoice')}</span>
              <span className="block text-tiny text-ink-medium">
                {`${t('cv.detail.unpaid.attachment')} `}
                {
                  unpaidInvoice?.orderInfo?.orders?.map((item) => (
                    <Anchor key={item} styling="text" href={route('app_order_list', true)} target={openLinksInNewTab ? '_blank' : undefined}>
                      <span className="text-tiny text-primary">
                        {` ${item as string}, `}
                      </span>
                    </Anchor>
                  ))
                }
              </span>
            </div>
          </div>
          )}
    </>
  );
};
